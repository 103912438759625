<template>
	<div class="winner-list row justify-content-center pt-3 pt-lg-4">
        <!-- loading -->
        <PagesLoading v-show="isPagesLoading"/>

		<div v-if="!isPagesLoading" class="col-12 col-xl-10" data-aos="fade-up" data-aos-duration="1500" data-aos-anchor-placement="top-bottom">
            <div class="winner-list__contain row justify-content-center">
                <div class="col-xl-8 mb-3">
                    <img v-if="deviceMode != 'mobile'" class="winner-list__title d-block mx-auto mb-4" src="@/assets/images/winner/img_title.png" alt="全民票選抽大獎獲獎名單">
                    <img v-else class="winner-list__title d-block mx-auto mb-4 mb-md-0" src="@/assets/images/winner/img_title_s.png" alt="全民票選抽大獎獲獎名單">				
                </div>
                <div class="col-xl-10">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="card d-flex flex-sm-row mb-4">
                                <div class="winner-list__gift">
                                    <img src="@/assets/images/winner/img_g1.png" alt="">
                                </div>
                                <div class="winner-list__info d-flex">
                                    <span class="info__title text-center text-danger">iPhone 12(128GB)</span>
                                    <small class="font-weight-bold">( 共<span class="info__num">1</span>名 )</small>
                                    <span v-if="winner_1[0]!==undefined">
                                        <span class="info__name">{{winner_1[0].Name}}</span> /
                                        <span class="info__phone">{{winner_1[0].Phone.replace(/(\d{4})\d{3}(\d{3})/, '$1***$2')}}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="card d-flex flex-sm-row mb-4">
                                <div class="winner-list__gift">
                                    <img src="@/assets/images/winner/img_g2.png" alt="">
                                </div>
                                <div class="winner-list__info d-flex">
                                    <span class="info__title text-center text-danger">Samsung Galaxy Watch Active2 44mm鋁製(藍牙)智慧手錶</span>
                                    <small class="font-weight-bold">( 共<span class="info__num">1</span>名 )</small>
                                    <span v-if="winner_2[0]!==undefined">
                                        <span class="info__name">{{winner_2[0].Name}}</span> /
                                        <span class="info__phone">{{winner_2[0].Phone.replace(/(\d{4})\d{3}(\d{3})/, '$1***$2')}}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="card mb-4">
                                <div class="row">
                                    <div class="col-lg-6 d-md-flex">
                                        <div class="winner-list__gift">
                                            <img src="@/assets/images/winner/img_g3.png" alt="">
                                        </div>
                                        <div class="winner-list__info d-flex">
                                            <span class="info__title text-center text-danger">將捷金鬱金香酒店<br>標準客房 平日住宿券</span>
                                            <small class="font-weight-bold">( 共<span class="info__num">10</span>名 )</small>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 d-flex flex-wrap text-center align-self-center justify-content-center justify-content-md-start">
                                        <template v-if="winner_3[0]!==undefined">  
                                            <span v-for="i in winner_3" :key="i" class="d-inline-block" :class="{'w-50': deviceMode!=='mobile'}">
                                                <span class="info__name">{{i.Name}}</span> /
                                                <span class="info__phone">{{i.Phone.replace(/(\d{4})\d{3}(\d{3})/, '$1***$2')}}</span>
                                            </span>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="card d-flex flex-sm-row flex-lg-column mb-4">
                                <div class="winner-list__gift">
                                    <img src="@/assets/images/winner/img_g4.png" alt="">
                                </div>
                                <div class="winner-list__info d-flex">
                                    <span class="info__title text-center text-danger">SONY 可攜式藍牙喇叭 SRS-XB23</span>
                                    <small class="font-weight-bold">( 共<span class="info__num">1</span>名 )</small>
                                    <span v-if="winner_4[0]!==undefined">
                                        <span class="info__name">{{winner_4[0].Name}}</span> /
                                        <span class="info__phone">{{winner_4[0].Phone.replace(/(\d{4})\d{3}(\d{3})/, '$1***$2')}}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="card d-flex flex-sm-row flex-lg-column mb-4">
                                <div class="winner-list__gift">
                                    <img src="@/assets/images/winner/img_g5.png" alt="">
                                </div>
                                <div class="winner-list__info d-flex">
                                    <span class="info__title text-center text-danger">Audio-Technica 鐵三角<br>真無線耳機 ATH-SQ1TW</span>
                                    <small class="font-weight-bold">( 共<span class="info__num">1</span>名 )</small>
                                    <span v-if="winner_5[0]!==undefined">
                                        <span class="info__name">{{winner_5[0].Name}}</span> /
                                        <span class="info__phone">{{winner_5[0].Phone.replace(/(\d{4})\d{3}(\d{3})/, '$1***$2')}}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="card d-flex flex-sm-row flex-lg-column mb-4">
                                <div class="winner-list__gift">
                                    <img src="@/assets/images/winner/img_g6.png" alt="">
                                </div>
                                <div class="winner-list__info d-flex">
                                    <span class="info__title text-center text-danger">日本麗克特récolte<br>格子三明治機</span>
                                    <small class="font-weight-bold">( 共<span class="info__num">1</span>名 )</small>
                                    <span v-if="winner_6[0]!==undefined">
                                        <span class="info__name">{{winner_6[0].Name}}</span> /
                                        <span class="info__phone">{{winner_6[0].Phone.replace(/(\d{4})\d{3}(\d{3})/, '$1***$2')}}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="card mb-4">
                                <div class="row">
                                    <div class="col-lg-6 d-md-flex">
                                        <div class="winner-list__gift">
                                            <img src="@/assets/images/winner/img_g7.png" alt="">
                                        </div>
                                        <div class="winner-list__info d-flex">
                                            <span class="info__title text-center text-danger">滬尾藝文休閒園區<br>商品券</span>
                                            <small class="font-weight-bold">( 共<span class="info__num">15</span>名 )</small>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 d-flex flex-wrap text-center align-self-center justify-content-center justify-content-md-start">
                                        <template v-if="winner_7[0]!==undefined">  
                                            <span v-for="i in winner_7" :key="i" class="d-inline-block" :class="{'w-50': deviceMode!=='mobile'}">
                                                <span class="info__name">{{i.Name}}</span> /
                                                <span class="info__phone">{{i.Phone.replace(/(\d{4})\d{3}(\d{3})/, '$1***$2')}}</span>
                                            </span>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	</div>
</template>

<script>
import PagesLoading from '@/components/PagesLoading.vue';

export default {
    components: {
        PagesLoading,
    },
    data() {
        return {
            allWinners:[],
            winner_1:[],
            winner_2:[],
            winner_3:[],
            winner_4:[],
            winner_5:[],
            winner_:[],
            winner_7:[],
            isPagesLoading: true
        }
    },
    created() {
        this.axios({
            method: 'get',
            url: 'https://api.2021tamsui.tw/Eve/T21Get',
        })
        .then(response => {
            this.allWinners = response.data;
            this.isPagesLoading = false;
            this.winner_1 = this.allWinners.filter(item => item.Awards == '1');
            this.winner_2 = this.allWinners.filter(item => item.Awards == '2');
            this.winner_3 = this.allWinners.filter(item => item.Awards == '3');
            this.winner_4 = this.allWinners.filter(item => item.Awards == '4');
            this.winner_5 = this.allWinners.filter(item => item.Awards == '5');
            this.winner_6 = this.allWinners.filter(item => item.Awards == '6');
            this.winner_7 = this.allWinners.filter(item => item.Awards == '7');
        })
    },
    mounted() {
    },
    methods: {
    }
}
</script>
